import styles from "./fileAttachment.module.css";
import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import attachment from "../../../assets/images/attachmentlogo.png"
import deleteIcon from "../../../assets/images/delete.png"
import FileUpload from "./fileUpload/FileUpload";
import { Loader } from "../../../components/loader/Loader";

const FileAttachment = (props) => {
    const { setFieldValue } = props;
    let [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState('');
    const fileValidator = (file) => {
        if (file.size > 5000000) {            
            return {
                code: "size-too-large",
                message: `file is larger than 5MB`,
            };
        }
        return null;
    }
    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        //maxFiles: 3,
        multiple: true,
        // validator: fileValidator,
        // accept: {     // refer this link --- https://stackoverflow.com/questions/4212861/what-is-a-correct-mime-type-for-docx-pptx-etc
        //     'image/*': ['.jpeg','.png',],
        //     'application/pdf': ['.pdf'],
        //     'application/vnd.ms-excel': ['.xls',],
        //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':['.xlsx'],
        //     'application/msword':['.doc'],
        //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document':['.docx']
        // },
        onDrop: async (acceptedFiles) => {
            const maxSize = 2 * 1024 * 1024; //2MB

            if(files.length + acceptedFiles.length > 15){
                setError("You can upload max 15 files.");
                return;
            }else{
                setError('');
            }

            for(const file of acceptedFiles){ // checking for file size
                if(file.size > maxSize){
                    setError(`File ${file.name} is exceeds 2MB limit`);
                    return 
                }else{
                    setError('');
                }
            }

            for(const file of acceptedFiles){
                setUploading(true);
                let result = await props.upload({file});
                if(result.status){
                    let obj={
                        originalFileName:result.res?.originalFileName,
                        uniqueFileName:result.res?.uniqueFileName
                        //url:result.res?.url
                    }
                    setFiles(files=[...files,obj]);
                   // let file_urls=files.map(item=>item.url)
                    setFieldValue(props.name, files);  // set formik field value
                    setUploading(false);
                }else{
                    setUploading(false);
                    setError(`File ${file.name} have not been uploaded correctly`);
                }
            }
        },
        onFileDialogOpen:()=>{
            setError('');
        }
        ,onDropRejected:(rejectedFiles)=>{
            //let unsupportedFiles=rejectedFiles.map((file)=> `${file.name} ${file.type}`).join(", ");
            setError(`Unsupported file formats`);
        }
    });

    const removeFile = (file, index) => () => {
        const allFiles = [...files];
        allFiles.splice(index, 1)
        setFiles(allFiles);
        //let file_urls=allFiles.map(item=>item.url)
        setFieldValue(props.name, allFiles);  // set formik field value
    }
    const thumbs = files.map((file, index) => (
        <div className={styles.thumb} key={index}>
            <div className={styles.thumbInner}>
                <div className={styles.attachment}>
                    <img className={styles.preview}
                        src={attachment}
                    //onLoad={() => { URL.revokeObjectURL(file.preview) }} alt="imageIcon"
                    />
                </div>
                <div className={styles.fileName}>{file.originalFileName}</div>
                <div className={styles.deleteIcon}>
                    <img
                        src={deleteIcon}
                        onClick={removeFile(file, index)}
                    />
                </div>
            </div>
        </div>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => {
        return (
            <div className={styles.fileSizeError}>{file.path} {errors.map(e => e.message)}
            </div>
        )
    });
   

    useEffect(() => {
        if(props.value?.length==0 || props.value === null){  // Reset files on form submit
            setFiles(files=[]);
            setError('')
        }else{
            setFiles(files=props.value); //CUP-643 Defect Fix
        }
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        //return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [props.value]);

    return (
        <React.Fragment>
            <div className={styles.message}><span className={styles.spannote}>Note: </span>Please do not upload any documents and files that contain sensitive personal data or confidential information.</div>
            <div className={styles.thumbsContainer} id="thumbContainer">
                {thumbs}
                {fileRejectionItems}
            </div>
            {uploading && <div><Loader style={styles.uploadLoader}/></div>}
            { error &&  <div className={styles.fileSizeError}>{error}</div>}
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {/* <img src={uploadFileContainer} className={styles.fileUploadContainer} /> */}
                <FileUpload/>
            </div>
        </React.Fragment>
    );
}

export default FileAttachment;