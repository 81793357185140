import React, { useEffect, useRef, useState } from "react";
import { Breadcrumbs } from "../../../../components/breadcrumbs/Breadcrumbs";
import { Banner } from "../../../../components/banner/Banners";
import IMAGES from "../../../../assets/images/images";
import { useParams } from "react-router-dom";
import {
  ChildContainer,
  Container,
} from "../../../../components/Container/Containers";
import styles from "./incidentDetail.module.css";
import { useNavigate } from "react-router-dom";
import AddComment from "../../addComment/addComment.container";
import AddTicket from "../add/addTicket.container";
import { Loader } from "../../../../components/loader/Loader";
import { ADD_TICKET_ACTION, COMMENT_SOURCE_TYPE, STATUS_ENUM } from "../../../../utils/constants/incidentConstants";
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
import { formatDate, futureDate } from "../../../../utils/arrayUtils";
import pdf from "../../../../assets/Vodafone_Carrier_Desk_Escalation.pdf"
import SectionGuard from "../../../../hoc/guards/SectionGuard";
import CAN from "../../../../casl/can";
import { Action, Resource } from "../../../../casl/constants";
import { USER_TYPE } from "../../../../utils/constants/userConstants";

const Escalationinfo = [
  {  
  level: 'Level 1',
  GSDShiftlead: 'GSD Shiftlead',
  EM:' EM Contact Number -',
  contactnumber: ' +44 149 150 2299,',
  IVR:' IVR Option',
  IVRoption: ' 1'
  },
  {  
    level: 'Level 2',
    GSDShiftlead: 'GSD Team Leader',
    EM:'EM Contact Number -',
    contactnumber: ' +44 149 150 2299,',
    IVR:' IVR Option',
    IVRoption: ' 2'
    },
    {  
      level: 'Level 3',
      GSDShiftlead: 'GSD Operations Manager',
      EM:'EM Contact Number -',
      contactnumber: ' +44 149 150 2299,',
      IVR:' IVR Option',
      IVRoption: ' 3'
      },
      {  
        level: 'Level 4',
        GSDShiftlead: 'GSD Senior Operational Manager',
        EM:'EM Contact Number -',
        contactnumber: ' +44 149 150 2299,',
        IVR:' IVR Option',
        IVRoption: ' 4'
        },
        {  
          level: 'Level 5',
          GSDShiftlead: 'GSD Senior Global Managers',
          Amr:'Amr Elkharbotly & Philip Earlam',
          EM:'EM Contact Number -',
          contactnumber: ' +44 149 150 2299,',
          IVR:' IVR Option',
          IVRoption: ' 5'
          },
         
]
const Escalationlist = ({data}) => {
  return (
    <>
     <div className={styles.escalationlist}>
            <div className={styles.leveldiv}>{data.level}</div>
            <div className={styles.listdiv}>
            <div className={styles.listcontent}>{data.GSDShiftlead}</div>
            {data?.Amr? <div className={styles.listcontent}>{data?.Amr}</div>:<></>}
            <div className={styles.listcontent}>{data.EM}<span className={styles.fontweight}>{data.contactnumber}</span>{data.IVR}<span className={styles.fontweight} >{data.IVRoption}</span> </div>
            </div>
          </div>
    </>
  );
};
const Escalation = ({ hideExpirepopup, data , itsmId, status }) => {
  const close = () => {
    hideExpirepopup();
  };
  const downloadLinkRef = useRef(null);

  const handleDownloadClick = () => {
    downloadLinkRef.current.click();
  };

  return (
    <div className={styles.warningpopup}>
      <div className={styles.warningcontent}>
        <div className={styles.headflex}>
          <div className={styles.head}>
            Escalation Matrix
            <div className={styles.downloadpdf} onClick={handleDownloadClick}>
            <span className={styles.downloadtxt}>Download</span>
            <img className={styles.downloadicon} src={IMAGES.downloadlogo} />
            <a ref={downloadLinkRef} href={pdf} download></a>
            </div>
          </div>
          <div className={styles.closediv}>
            <img
              onClick={close}
              className={styles.closeimg}
              src={IMAGES.close}
            />
          </div>
        </div>
        <div className={styles.headcontent}>
          {status===STATUS_ENUM.NEW?(
            <span> Before contacting us, please review the escalation principles outlined below.</span>
          ):(
            <span>Before reaching out to us, please make a note of your OneITSM ID - <span className={styles.excellectioncontent}>{itsmId}</span> and share it with the service desk team if needed. Additionally, please review the escalation principles outlined below.</span>
          )}
        </div>
        <div className={styles.line}></div>
        <div className={styles.escalationcontent}>
          <div className={styles.escalationlists}>
            <div className={styles.leveldivs}>
              You are here<div className={styles.servicediv}>Service</div>
              <div className={styles.servicediv}> Desk</div>
            </div>
            <div className={styles.listdivs}>
              <div className={styles.listcontents}>
                <ul>
                  <li>
                    Service Desk :
                    <span className={styles.fontweight}>
                      {" "}
                      +44(0) 800 048 2088/ +44 207 138 7088
                    </span>{" "}
                    (opt 1 or 3 )
                    <span className={styles.fontweight}>
                      {" "}
                      cscvcs@vodafone.com{" "}
                    </span>
                  </li>
                </ul>
              </div>
              <div className={styles.listcontents}>
                <li>Owns the End to End Incident Management Process</li>
              </div>
              <div className={styles.listcontents}>
                <li>Engages Level 1</li>
              </div>
              <div className={styles.listcontents}>
                <li>Communication Updates</li>
              </div>
            </div>
          </div>
          {Escalationinfo.map((item) => (
            <Escalationlist data={item} />
          ))}

          <div className={styles.principlesmain}>
            <div className={styles.principleshead}>Escalation Principles</div>
            <div className={styles.principlesinfo}>
              <li>An escalation may be made at any time </li>
            </div>
            <div className={styles.principlesinfo}>
              <li>
                An escalation should be accepted at any level and managed
                accordingly
              </li>
            </div>
            <div className={styles.principlesinfo}>
              <li>
                Escalate should take place from bottom to top as per the table
                in the next page.
              </li>
            </div>
            <div className={styles.principlesinfo}>
              <li>Escalation should be peer to peer.</li>
            </div>
            <div className={styles.principlesinfo}>
              <li>Time, impact and criticality should drive escalation</li>
            </div>
            <div className={styles.principlesinfo}>
              <li>
                Please be clear when outlining the reason for escalation and the
                required response
              </li>
            </div>
            <div className={styles.principlesinfo}>
              <li>
                Timescales for a response will be agreed at the point of
                escalation
              </li>
            </div>
            <div className={styles.principlesinfo}>
              <ul>
                <li>
                  All levels are available 24x7 but to ensure all escalations
                  are answered in the required time, they should be requested
                  via the Service desk, over the phone, as emails are not
                  monitored and as such as no SLA.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.buttons}>
          <button className={styles.logout} onClick={close}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const useViewMoreLess = (text, maxLength) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpansion = () => {
      setIsExpanded(!isExpanded);
  };
  const isTextLong = text?.length > maxLength;
    const displayText = isExpanded || !isTextLong ? text : `${text.substring(0, maxLength)}...`;
    const linkText = isExpanded ? 'View Less' : 'View More';
    return { displayText, linkText, toggleExpansion, isTextLong };
};

const DetailContainer = ( {data, myRights} ) => {
  const [isEscalationOpen, setIsEscalationOpen] = useState(false);
  const { displayText, linkText, toggleExpansion, isTextLong } = useViewMoreLess(data?.incidentDetail?.issueDetails?.title, 50);

  const handleEscalationClick = () => {
    setIsEscalationOpen(true);
  };
  const hideExpirepopup = () => {
    setIsEscalationOpen(false);
  };


  const navigate = useNavigate();

  const back = (requestId) => {
    navigate(`/incident-management`);
  };

  return (
    data.loading?(
      <div className={styles.comentsLoader}>
      <Loader />
      </div>
    ):(
      <div className={styles.headero}>
      <div className={styles.back}>
        <div>
          <span>
            <img
              className={styles.backbutton}
              src={IMAGES.backarrow}
              onClick={() => back()}
            />
          </span>
          <span className={styles.id}>{data?.incidentDetail?.incidentId}</span>
          <span  className={`${styles.status} ${styles[data?.incidentDetail?.status?.value]}`}>
            {data?.incidentDetail?.status?.label}
          </span>
        </div>
        <div className={styles.escalationmatrix}>
          <span className={styles.escalation} onClick={handleEscalationClick}>
            Escalation Matrix
          </span>
          {isEscalationOpen && <Escalation hideExpirepopup={hideExpirepopup} itsmId={data?.incidentDetail?.ticketIdServiceDesk} status={data?.incidentDetail?.status?.value}/>}
        </div>
      </div>
      {data?.incidentDetail?.status?.value===STATUS_ENUM.CLOSED&&(
        <div className={styles.closedItsmid}><img className={styles.circleinfo} src={IMAGES.infoCircle}/>Please refer to OneITSM ID - <span className={styles.ticketIdServiceDesk}>{data?.incidentDetail?.ticketIdServiceDesk}</span> if you need to reopen the ticket or contact the service desk team.</div>
      )}
        <div className={styles.detailContainer}>
        <div className={styles.detailContent}>
          <div className={styles.summary}>
            {/* <span className={styles.issue}>Title : </span> */}
            {/* {displayText}
            {isTextLong && (
                <span onClick={toggleExpansion} className={styles.viewMore}>{linkText}</span>
            )} */}
            <span className={styles.title}>{data?.incidentDetail?.issueDetails?.title}</span>
          </div>
          <div className={styles.titleContainer}>
          {myRights?.userType===USER_TYPE.INTERNAL&&(
              <>
                <div className={`${styles.child} ${styles.sepration}`}>
                 Customer Name <span className={styles.data}>
                 {data?.incidentDetail?.createdBy?.name}
                 </span>
                </div>
              </>
            )}
          {data?.incidentDetail?.status?.value!==STATUS_ENUM.NEW&&(
              <>
                <div className={`${styles.child} ${styles.sepration} ${myRights?.userType===USER_TYPE.INTERNAL?styles.borderPadding:""}`}>
                 OneITSM ID <span className={styles.data}>
                 {data?.incidentDetail?.ticketIdServiceDesk}
                 </span>
                </div>
              </>
            )}
            <div className={`${styles.child} ${(data?.incidentDetail?.status?.value!==STATUS_ENUM.NEW||myRights?.userType===USER_TYPE.INTERNAL)?styles.borderPadding:""}  ${styles.sepration}`}>
              Service Type <span className={styles.data}>{data?.incidentDetail?.incidentServiceType?.label}</span>
            </div>
            <div className={`${styles.child} ${styles.sepration} ${styles.borderPadding}`}>
              Fault Type <span className={styles.data}>{data?.incidentDetail?.incidentFaultType?.label}</span>
            </div>
            <div className={`${styles.child} ${styles.borderPadding} ${styles.sepration}`}>
                Service Fault Type <span className={styles.data}>{data?.incidentDetail?.failureRelatedType?.label}</span>
               </div>
                <div className={`${styles.child} ${styles.borderPadding}`}>
                 Submitted On <span className={styles.data}>{formatDate(data.incidentDetail.createdAt)}</span>
               </div>
          </div>
          <div className={styles.viewmore}><AddTicket incidentData={data.incidentDetail} actionType={ADD_TICKET_ACTION.INCIDENT_DETAILS}/></div>
        </div>
        </div>
    </div>
    )
  );
};

const ActivityIndicator = ({data})=>{
  return (
    <>
    {data?.incidentDetail?.incidentDetail?.status?.value===STATUS_ENUM.NEED_MORE_INFORMATION &&(
         <div className={styles.activityContainer}>
         <div className={styles.addinfoContainer}>
           <div className={styles.contentInfo}>Please provide additional information requested by Service Desk</div>
           <span className={styles.warning}><img className={styles.circleinfo}src={IMAGES.infoCircle}/>Respond by {futureDate(data?.incidentDetail?.incidentDetail?.modifiedAt)}, or the ticket will be auto closed.</span>
         </div>
         <div className={styles.provideinfoContainer}>
           <div className={styles.providebtn}>
           <AddComment source={COMMENT_SOURCE_TYPE.PROVIDE_INFORMATION} data={data?.incidentDetail} />
           </div>
         </div>
       </div>
    )}
    {data?.incidentDetail?.incidentDetail?.status?.value===STATUS_ENUM.CUSTOMER_FEEDBACK_AWAITED &&(
        <div className={styles.activityContainer}>
        <div className={styles.feedbacktext}>
          <div className={styles.feedbackInfo}>Are you satisfied with the ticket resolution?</div>
          <span className={styles.warning}><img className={styles.circleinfo}src={IMAGES.infoCircle}/>Please respond by {futureDate(data?.incidentDetail?.incidentDetail?.modifiedAt)} to accept or reject the ticket resolution.</span>
        </div>
        <div className={styles.feedbacaction}>
          <div className={styles.feedbackbtn}>
           <AddComment source={COMMENT_SOURCE_TYPE.NOT_RESOLVED} data={data?.incidentDetail} />
           <AddComment source={COMMENT_SOURCE_TYPE.ACCEPTED} data={data?.incidentDetail} />
          </div>
        </div>
      </div>
     )}
    </>
  )
};

const CommentDetail = ({data, onFileDownload}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const text = data?.commentMessage;
  const html = data?.htmlComment;
  const previewText = text?.slice(0, 100);
  function getInitials(name) {
    const displayName = name.split(' ').filter(word => isNaN(word) && word).filter((_, i, arr) => i === 0 || i === arr.length - 1)
        .map(word => word[0]).join('').toUpperCase();
    return displayName;
  }
  const downloadFile = (file, index,onDownload) => {   
    onDownload(file)
  }
  const showViewMore = text?.length>100

  return (
    <div className={styles.commentContainer}>
      <div className={styles.initalshead}>
        <div className={styles.initaltext}>
          <span className={`${styles.inital} ${data?.createdBy?.name==="Service Desk"?styles.servicedesk:styles.customer}`}>{getInitials(data?.createdBy?.name)}</span>
          <span className={styles.initatname}>{data?.createdBy?.name}</span>
          {/* {((!isExpanded && showViewMore &&data?.commentAttachments?.length>0))? (
            <img className={styles.infoattach} src={IMAGES.attachinfo} />
          ) : (
            <></>
          )} */}
          {data?.commentAttachments?.length>0&&(
             <img className={styles.infoattach} src={IMAGES.attachinfo} />
          )}
          {/* <span className={styles.warning}><img className={styles.circleinfo}src={IMAGES.infoCircle}/>Respond within 48 hours, or the ticket will be auto closed.</span> */}
        </div>
        <div className={styles.attachments}>
          Posted On:
          <span className={styles.date}>
            {formatDate(data?.createdAt)}
          </span>
        </div>
      </div>
      <div className={styles.comment}>
        {data?.createdBy?.name==="Service Desk"?
        <div dangerouslySetInnerHTML={{ __html: html }} />:
        <>
        {/* {isExpanded ? text : `${previewText}${showViewMore?"...":""}`} */}
        
        {data?.commentMessage.split('\n').map(text => <span className={styles.portalcomment}>{text}</span>)}
        {/* {showViewMore&&(
          <span className={styles.viewMore} onClick={handleToggle}>
           {isExpanded ? " View Less" : " View More"}
          </span>
        )} */}
        </>}
      </div>
      {/* {((isExpanded && showViewMore) || (data?.commentAttachments?.length>0 && !showViewMore))? ( */}
        <div className={styles.parent}>
        {data?.commentAttachments?.map((item,index)=>
        <div className={styles.attachment} key={index} title={item?.originalFileName}>
        <img className={styles.attachlogos} src={IMAGES.attachment} />
        <span className={styles.filename}>{item?.originalFileName}</span>
        <img className={styles.attachlogo} src={IMAGES.downloadlogo} onClick={() => downloadFile(item, index,onFileDownload)}  />
      </div>)}</div>
      {/* ) : (
        <></>
      )} */}
    </div>
  );
};

const IncidentDetail = (props) => {
  let { requestId } = useParams();
  useEffect(() => {
    props.getIncidentDetail(requestId);
    let next=null;
    props.getIncidentComments(requestId,next);
    window.scrollTo(0,0);
    return () => {
      props.clearIncidentComments();
  };
  }, [requestId]);

  const fetchData = () => {
    if (props.next) {
      props.getIncidentComments(requestId,props.next);
    }
  };
  
  const onFileDownload=(file)=>{   
    props.incidentDownloadFile(file.originalFileName,file.uniqueFileName,file.messageId,file.attachmentId,file.graphInternetMessageId)
    .then((result)=>{
        if(result.status){
          const fileData=result.file
          if(fileData?.length>0){
                window.open(fileData?.[0]);
          }
        }else{
          alert("Error while downloding file!");
        }
      }).catch((error)=>{
        alert("Something went wrong!")
      })
    }

  return (
    <Container>
      <Banner image={IMAGES.banner} text={"Incident Details"}></Banner>
      <ChildContainer>
        <DetailContainer data={props.incidentDetail} myRights={props?.myRights} />
        {!props?.incidentDetail?.loading&&(
          <SectionGuard canAccess={CAN(Action.CREATE_COMMENT, Resource.INCIDENTS)}>
            <ActivityIndicator  data={props}/>
          </SectionGuard>
        )}
        <div className={styles.listhead}>
          <div className={styles.initaltext}>Comment History</div>
          <div>
            {(props?.incidentDetail?.incidentDetail?.status?.value===STATUS_ENUM.NEW || props?.incidentDetail?.incidentDetail?.status?.value===STATUS_ENUM.IN_PROGRESS)&&!props?.incidentDetail?.loading&&( 
              <SectionGuard canAccess={CAN(Action.CREATE_COMMENT, Resource.INCIDENTS)}>
                <AddComment source={COMMENT_SOURCE_TYPE.REGULAR_COMMENT} data={props.incidentDetail} />
              </SectionGuard>
             )}
          </div>
        </div>
        {props.isCommentsLoading && props.comments.length == 0? (
          <div className={styles.comentsLoader}>
            <Loader />
          </div>
      ) : props.comments.length == 0 ? (
        <div className={styles.emptydiv}>No Records Found</div>
      ) : (
        <InfiniteScroll
          dataLength={props?.comments?.length} //This is important field to render the next data
          next={() => {
            fetchData();
          }}
          hasMore={props.next}
          loader={<Loader />}
        >
          {props?.comments?.map((item) => (
            <CommentDetail data={item} onFileDownload={onFileDownload}/>
          ))}
        </InfiniteScroll>
      )}
      </ChildContainer>
    </Container>
  );
};

export default IncidentDetail;
