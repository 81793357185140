import React, { useRef } from "react";
import styles from "./navBar.module.css";
import logo from "../../assets/images/logo.png";
import { logOut } from "../../authActions/authActions";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CAN from "../../casl/can";
import { Action, Resource } from "../../casl/constants";
import IMAGES from "../../assets/images/images";
import Overlaydropdown from "../../components/overlaydropdown/Overlaydropdown";
import { connect } from "react-redux";
import { MY_SERVICE_TABS } from "../../utils/constants/serviceConstants";
import { Incident_Service_Type_Values, MY_INCIDENT_TABS } from "../../utils/constants/incidentConstants";
import { setServiceActiveTab } from "../../redux/actions/newService/service.actions";
import { setIncidentActiveTab } from "../../redux/actions/incidentManagement/incidentTab.action";
import Notification from "../notification/Notification";
import { FUNCTIONAL_ROLES_CONSTANTS, USER_TYPE } from "../../utils/constants/userConstants";
import { setActivePrimaryTab, setActiveSecondaryTab } from "../../redux/actions/userManagement/userManagementTab.action";
import { My_Request_SUBTAB, UserManagementTab } from "../../utils/constants/userManagementConstant";
import { formatDate } from "../../utils/arrayUtils";


function Navbar(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState("/dashboard");
  const [initials, setInitials] = useState("");

  const overlayRef = useRef();

  const gotoPage = (path) => {
    navigate(path);
    setSelectedItem(path);
  };

  const canAccess = [
    CAN(Action.LIST, Resource.CUSTOMERS) ,
    CAN(Action.LIST, Resource.RIGHTS),
    CAN(Action.GET, Resource.CUSTOMERS),
    CAN(Action.LIST, Resource.CUSTOMER_ONBOARDING_REQUEST)
  ].some(condition => condition);
  

  let Items = [
    {
      path: "/dashboard",
      label: "Dashboard",
      canAccess: CAN(Action.LIST, Resource.IPX_POP),
    },
    {
      path: "/connectivity",
      label: props.myRights?.userType==USER_TYPE.INTERNAL?"Connectivity":"My Connectivity",
      canAccess:
        CAN(Action.LIST, Resource.LIVE_SERVICES) ||
        CAN(Action.LIST, Resource.DEMANDS),
    },
    {
      path: "/insights",
      label: "Insights",
      canAccess: CAN(Action.GET, Resource.DIAMETER) || CAN(Action.GET, Resource.SIGTRAN) || CAN(Action.GET, Resource.WEATHERMAPS),
    },
    {
      path: "/incident-management",
      label: props.myRights.userType===USER_TYPE.INTERNAL?"Tickets":"My Tickets",
      canAccess: CAN(Action.LIST, Resource.INCIDENTS) || CAN(Action.LIST, Resource.SERVICE_REQUESTS),
    },
    {
      path: "/user-management",
      label: "User Management",
      canAccess: canAccess
    },
    {
      path: "/contact-us",
      label: "Contact Us",
      canAccess: CAN(Action.CREATE, Resource.QUERY),
    },
  ];

  // useEffect(() => {
  //   navigate("/dashboard");
  // }, [navigate]);
// console.log("props Nav",props);

  useEffect(() => {
    const displayName = props.myRights?.displayName
        .split(' ')
        .filter(word => isNaN(word) && word)
        .filter((_, i, arr) => i === 0 || i === arr.length - 1)
        .map(word => word[0])
        .join('')
        .toUpperCase();
      setInitials(displayName);
  }, []);

  useEffect(() => {
    setSelectedItem(location.pathname);
    
    // set default tab live service 
    if(location.pathname != "/connectivity" && !location.pathname.startsWith("/demands")){
        if(CAN(Action.LIST, Resource.DEMANDS) && CAN(Action.LIST, Resource.LIVE_SERVICES)){
              if(props.serviceActiveTab != MY_SERVICE_TABS.LIVE_SERVICES){
                props.setServiceActiveTab(MY_SERVICE_TABS.LIVE_SERVICES);
              }
        }
    }
    // set default tab My Incidents 
    if(location.pathname != "/incident-management" && !location.pathname.startsWith("/incidentDetail") && !location.pathname.startsWith("/ServiceRequestDetail")){
      if(props.incidentActiveTab != MY_INCIDENT_TABS.MY_INCIDENTS){
        props.setIncidentActiveTab(MY_INCIDENT_TABS.MY_INCIDENTS);
      }
}
  // set default tab Customers
   if(location.pathname != "/user-management" && !location.pathname.startsWith("/OnboardingDetail") && !location.pathname.startsWith("/OnboardingRequestDetail") && !location.pathname.startsWith("/accountAccessRequest") && !location.pathname.startsWith("/accountAccessApproval")){
          if(props.activePrimaryTab != UserManagementTab.CUSTOMER){
            props.setActivePrimaryTab(UserManagementTab.CUSTOMER);
          }
          if(props.activeSecondaryTab != My_Request_SUBTAB.ON_BOARDING_REQUEST){
            props.setActiveSecondaryTab(My_Request_SUBTAB.ON_BOARDING_REQUEST);
          }
}

  }, [location, navigate]);

  const roleLabel = FUNCTIONAL_ROLES_CONSTANTS[props.myRights?.functionalRoles[0]]?.label;

  return (
    <>
      <div className={styles.navmain}>
        <ul className={styles.navul}>
          <img className={styles.logo} src={logo} alt="Logo" />
          {Items.map((item) =>
            item.canAccess ? (
              <li key={item.path} className={styles.navli}>
                <button
             className={`${styles.btnNavItems} 
             ${
              item.path === selectedItem ||
               ((item.path === "/connectivity" &&
                 (location.pathname.startsWith("/demands/")))
               ) ||  ((item.path === "/insights" &&
               (location.pathname.startsWith("/insights/")))
               )||  ((item.path === "/incident-management" &&
                (location.pathname.startsWith("/ServiceRequestDetail/")|| location.pathname.startsWith("/incidentDetail/")))
               )||((item.path === "/user-management" &&
                (location.pathname.startsWith("/OnboardingDetail/")|| location.pathname.startsWith("/OnboardingRequestDetail/")|| location.pathname.startsWith("/accountAccessRequest/")|| location.pathname.startsWith("/accountAccessApproval/")))
               )
                 ? styles.selected
                 : ""
             }`}
                  onClick={() => gotoPage(item.path)}
                >
                  {item.label}
                </button>
              </li>
            ) : (
              <></>
            )
          )}
        </ul>

        <div className={styles.navicon}>
          {/* <div>
            <button
              className={styles.logoutbtn}
              onClick={() => {
                logOut();
              }}
            >
              Logout
            </button>
          </div> */}      
          {/* <div className={styles.notification}>
            <Notification/>
            </div>     */}
          <div className={styles.usericon}>
            <Overlaydropdown
              ref={overlayRef}
              initials={initials}    
              headerText="User Profile"
            >
             
             {/* <div className={styles.userDiv}>
                <div className={styles.userlabel}>Name</div> 
                <div className={styles.userdetail}>{props.myRights?.displayName}</div>
              </div>
              <div className={styles.userDiv}>
                <div className={styles.userlabel}>Email</div> 
                <div className={styles.userdetail}>{props.myRights?.email}</div>
              </div>
              {props.myRights?.userType==USER_TYPE.INTERNAL &&(
              <div className={styles.userDiv}>
                <div className={styles.userlabel}>Role</div> 
                <div className={styles.userdetail}>{FUNCTIONAL_ROLES_CONSTANTS[props.myRights?.functionalRoles[0]]?.label}</div>
              </div>
              )}
              <div>
            
          </div> */}
           
           <div>
            <div className={styles.logoutbtndiv}>    
               <span className={styles.logoutbtn}
               onClick={() => {
                logOut();
               }}
              >
              Logout </span>
            </div>
                <div className={styles.usermain}>
                  <div>   <div className={styles.userIcon} >{initials} </div></div>
                  <div style={{ marginTop: roleLabel ? '0px' : '10px' }}>
                    <div className={styles.userdetailn}>{props.myRights?.displayName}</div>
                    <div className={styles.userdetaile}>{props.myRights?.email}</div>
                    {props.myRights?.userType == USER_TYPE.INTERNAL && (
                      <div className={styles.userdetailr}>{FUNCTIONAL_ROLES_CONSTANTS[props.myRights?.functionalRoles[0]]?.label}</div>)}
                    {props.myRights?.userType == USER_TYPE.CUSTOMER && (
                      <>
                        <div className={styles.userdetailr}>{props?.myRights?.customerUserMetaInfo?.customerName}</div>
                        <div className={styles.userdetailr}>{props?.myRights?.customerUserMetaInfo?.myServices[0]?.serviceType?.value == Incident_Service_Type_Values.IPX_Service ? "IPX Service" : "International Voice Service"}</div>
                      </>
                    )}
                  </div>
                </div>
                <div> <div className={styles.userdetaill}><img className={styles.lastlogin} src={IMAGES.lastlogin} />Last Login Time: {props?.myRights?.lastLoginInTime ? formatDate(props?.myRights?.lastLoginInTime) : "Not Available"}</div></div>
           </div>
            </Overlaydropdown>
          </div>
        </div>
      </div>  
    </>
  );
}

const mapStateToProps = (state) => {
  return {
      myRights:state.myRights.myRights,
      serviceActiveTab:state.newService.service.serviceActiveTab,
      incidentActiveTab:state.incidentTab.incidentActiveTab,
      activePrimaryTab :state.tabReducer.activePrimaryTab,
      activeSecondaryTab :state.tabReducer.activeSecondaryTab
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
      setServiceActiveTab: (tab) => {
        return dispatch(setServiceActiveTab(tab))
    } , 
    setIncidentActiveTab: (tab) => {
      return dispatch(setIncidentActiveTab(tab))
    },
    setActivePrimaryTab: (tab) => {
      return dispatch(setActivePrimaryTab(tab))
    },
    setActiveSecondaryTab: (tab) => {
      return dispatch(setActiveSecondaryTab(tab))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
