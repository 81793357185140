import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Wrapper, TextField, TextArea, SelectField ,FormTitle,ImageUpload} from "../controllers/controllers";
import * as Yup from "yup";
import styles from "../controllers/controllers.module.css";
import { EditButton, Submit } from "../../../../components/button/buttons";
import IMAGES from "../../../../assets/images/images";
import {connect} from "react-redux";
import {setIncidentPreviewMode} from "../../../../redux/actions/incidentManagement/raiseIncident.action";
import { ADD_TICKET_ACTION, Priority_Options, Yes_No_Options} from "../../../../utils/constants/incidentConstants";
import {getLabelFromValue} from "../../../../utils/arrayUtils"
import {SubmitIncident} from "../../../../redux/actions/incidentManagement/raiseIncident.action";
import {openAlertPopup} from "../../../../redux/actions/Alert/alert.action";
import {getIncidentList} from "../../../../redux/actions/incidentManagement/incidentList.action";
import { incidentDownloadFiles } from "../../../../redux/actions/fileAttachment/fileAttachment.actions";

const IPPacketDropHighLatencyHighUtilizationDownForm = ({ service_type, issue_type, failure_type,parentFormData,actionType,incidentData, ...props }) => {
    const [isSubmit, setIsSubmit] = useState(false);
    const onFileDownload=(file)=>{
        props.incidentDownloadFile(file.originalFileName,file.uniqueFileName,file.messageId,file.attachmentId,file.graphInternetMessageId)
        .then((result)=>{
            if(!result.status){
                alert("Error while downloding file!");
            }
        }).catch((error)=>{
        alert("Something went wrong!")
        })
    }
    let initialValues = {}
    if(actionType===ADD_TICKET_ACTION.INCIDENT_DETAILS){
        initialValues = {
            issue_summary: incidentData?.issueDetails?.title,
            priority:incidentData?.issueDetails?.priority,
            date_time:incidentData?.issueDetails?.issueStartedDateTime,
            is_problem_persist:incidentData?.issueDetails?.problemPersist,
            bgp_ip_addresses:incidentData?.issueDetails?.bgpIPAddresses,
            source_ip_addresses_network:incidentData?.issueDetails?.sourceIPAddress,
            destination_ip_addresses_network_port:incidentData?.issueDetails?.destinationIPAddress,
            trace_route_source_text:incidentData?.issueDetails?.tracerouteSource,
            trace_route_source_file:incidentData?.issueDetails?.tracerouteDestinationAttachment,
            trace_route_destination_text:incidentData?.issueDetails?.tracerouteDestination,
            trace_route_destination_file:incidentData?.issueDetails?.pingResultsDestinationAttachment,
            ping_result_source_text:incidentData?.issueDetails?.pingResultsSource,
            ping_result_source_file:incidentData?.issueDetails?.pingResultsSourceAttachment,
            ping_result_destination_text:incidentData?.issueDetails?.pingResultsDestination,
            ping_result_destination_file:incidentData?.issueDetails?.pingResultsDestinationAttachment,
            additional_information:incidentData?.issueDetails?.additionalInformation
        };
    }else{
        initialValues = {
            issue_summary: '',
            priority:'',
            date_time:'',
            is_problem_persist:'',
            bgp_ip_addresses:'',
            source_ip_addresses_network:'',
            destination_ip_addresses_network_port:'',
            trace_route_source_text:'',
            trace_route_source_file:[],
            trace_route_destination_text:'',
            trace_route_destination_file:[],
            ping_result_source_text:'',
            ping_result_source_file:[],
            ping_result_destination_text:'',
            ping_result_destination_file:[],
            additional_information:'',
            impactedSubscriber:null,
        };
    }

    const validationSchema = Yup.object().shape({
        issue_summary: Yup.string().max(100, "Issue summary must be at most 100 characters").required("Required"),
        priority: Yup.string(),
        date_time:Yup.string().required("Required"),
        is_problem_persist:Yup.string().required("Required"),
        bgp_ip_addresses:Yup.string(),
        source_ip_addresses_network:Yup.string().required("Required"),
        destination_ip_addresses_network_port:Yup.string().required("Required"),
        trace_route_source_text:Yup.string().max(1000, "Text must be at most 1000 characters").test(
            {
                name:'Required',
                message:'At least one of the fields Traceroute (Source) description or supporting attachments required',
                test:function(value){
                    let file= this.resolve(Yup.ref('trace_route_source_file'));
                    let text=value;
                    return text?.trim().length > 0 || file.length > 0
                }
            }
        ),
        trace_route_source_file:Yup.array().max(15).test(
            {
                name:'Required',
                message:'At least one of the fields Traceroute (Source) description or supporting attachments required',
                test:function(value){
                    let text= this.resolve(Yup.ref('trace_route_source_text'));
                    let file=value;
                    return text?.trim().length > 0 || file.length > 0
                }
            }
        ),
        trace_route_destination_text:Yup.string().max(1000, "Text must be at most 1000 characters").test(
            {
                name:'Required',
                message:'At least one of the fields Traceroute (Destination) description or supporting attachments required',
                test:function(value){
                    let file= this.resolve(Yup.ref('trace_route_destination_file'));
                    let text=value;
                    return text?.trim().length > 0 || file.length > 0
                }
            }
        ),
        trace_route_destination_file:Yup.array().max(15).test(
            {
                name:'Required',
                message:'At least one of the fields Traceroute (Destination) description or supporting attachments required',
                test:function(value){
                    let text= this.resolve(Yup.ref('trace_route_destination_text'));
                    let file=value;
                    return text?.trim().length > 0 || file.length > 0
                }
            }
        ),
        ping_result_source_text:Yup.string().max(1000, "Text must be at most 1000 characters"),
        ping_result_source_file:Yup.array().max(15),
        ping_result_destination_text:Yup.string().max(1000, "Text must be at most 1000 characters"),
        ping_result_destination_file:Yup.array().max(15),
        additional_information:Yup.string().max(1000, "Text must be at most 1000 characters")
    })
    
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
                if(props.isIncidentPreviewModeOn){
                    setIsSubmit(true);           
                let obj = {
                    incidentServiceType: parentFormData.service_type,
                    incidentFaultType: parentFormData.issue_type,
                    failureRelatedType: parentFormData.failure_type,
                    impactedSubscriber: parentFormData.impacted_subscriber,
                    uniqueCustomerRefId:props.myRights?.customerUserMetaInfo?.uniqueCustomerRefId,
                    issueDetails: {
                        title: values.issue_summary,
                        priority: values.priority,
                        issueStartedDateTime: values.date_time,
                        problemPersist: values.is_problem_persist,
                        bgpIPAddresses: values.bgp_ip_addresses,
                        sourceIPAddress: values.source_ip_addresses_network,
                        destinationIPAddress: values.destination_ip_addresses_network_port,
                        tracerouteSource: values.trace_route_source_text,
                        tracerouteSourceAttachment: values.trace_route_source_file,
                        tracerouteDestination: values.trace_route_destination_text,
                        tracerouteDestinationAttachment: values.trace_route_destination_file,
                        pingResultsSource: values.ping_result_source_text,
                        pingResultsSourceAttachment: values.ping_result_source_file,
                        pingResultsDestination: values.ping_result_destination_text,
                        pingResultsDestinationAttachment: values.ping_result_destination_file,
                        additionalInformation: values.additional_information
                    }
                  };      
                props.addIncident(obj).then((result) => {        
                  if (result.status) {
                    props.openAlertPopup({
                      message: `A new incident with ID: ${result.res.data.incidentId} has been created.`,
                      image: IMAGES.success
                    });          
                     setIsSubmit(false);
                     props.closeForm();
                    resetForm();
                    props.getIncidentList();
                  } else {
                    let error_msg = result?.error;
                    props.openAlertPopup({
                      message: error_msg,
                      image: IMAGES.error,
                      isFormOpen:true
                    });  
                    setIsSubmit(false);
                 //   props.closeForm();
                  }
                })
                .catch((error) => {
                  props.openAlertPopup({
                    message: "Something went wrong.",
                    image: IMAGES.error,
                    isFormOpen:true
                  }); 
                   setIsSubmit(false);
              //     props.closeForm();
                });
            }else{
                props.setIncidentPreviewMode(true);
            }
            }}
        >
            {({ isValid, dirty, setFieldValue, values, handleChange, handleSubmit, handleBlur, resetForm }) => (
                <>
                    <FormTitle
                            title={actionType===ADD_TICKET_ACTION.INCIDENT_DETAILS?null:(props.isIncidentPreviewModeOn?"Please Check The Details":"Please Provide The Details")}
                    />

                    <Form 
                        id="ip_packet_drop_high_latency_high_utilization"
                        className={styles.form}
                        onSubmit={handleSubmit}
                    >
                        <TextField
                            label="Title"
                            type="text"
                            name="issue_summary"
                            isMandatory={true}
                            placeholder="Enter here"
                            isExpandedField={true}
                            showTooltip={true}
                            tooltipText={"Title"}
                            textLimit={"100 characters"}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                         <SelectField
                                label={"Priority"}
                                name="priority"
                                placeholder="Choose priority"
                                options={Priority_Options}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                showTooltip={true}
                                tooltipText={"Priority"}
                                isExpandedField={false}
                                isMandatory={false}
                                isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                         <TextField
                            label="Date & Time of Issue Started"
                            name="date_time"
                            isMandatory={true}
                            type="datetime-local"
                            placeholder="Enter here"
                            isExpandedField={false}
                            showTooltip={true}
                            tooltipText={"Date & Time of Issue Started"}
                            textLimit={null}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                        <SelectField
                            label={"Does the problem persist?"}
                            name="is_problem_persist"
                            placeholder="Select"
                            options={Yes_No_Options}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                            showTooltip={true}
                            tooltipText={"Does The Problem Persist?"}
                            isExpandedField={false}
                            isMandatory={true}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                        <TextField
                            label="BGP IP Addresses"
                            name="bgp_ip_addresses"
                            type="text"
                            isMandatory={false}
                            placeholder="Enter here"
                            isExpandedField={false}
                            showTooltip={true}
                            tooltipText={"BGP IP Addresses"}
                            textLimit={null}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                          <TextField
                            label="Source IP-Address or Network(s)"
                            name="source_ip_addresses_network"
                            type="text"
                            isMandatory={true}
                            placeholder="Enter here"
                            isExpandedField={false}
                            showTooltip={true}
                            tooltipText={"Source IP-Address or Network(s)"}
                            textLimit={null}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                         <TextField
                            label="Destination IP-Address or Network(s) or Port(s)"
                            name="destination_ip_addresses_network_port"
                            type="text"
                            isMandatory={true}
                            placeholder="Enter here"
                            isExpandedField={false}
                            showTooltip={true}
                            tooltipText={"Destination IP-Address or Network(s) or Port(s)"}
                            textLimit={null}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                         <TextArea
                            label="Traceroute (Source)"
                            name="trace_route_source_text"
                            isMandatory={true}
                            placeholder="Enter here"
                            isExpandedField={true}
                            showTooltip={true}
                            tooltipText={"Traceroute (Source)"}
                            textLimit={"1000 characters"}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                        <ImageUpload
                            name="trace_route_source_file"
                            value={values.trace_route_source_file}
                            setFieldValue={setFieldValue}
                            isMandatory={true}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                            onFileDownload={onFileDownload}
                            actionType={actionType}
                        />
                        <TextArea
                            label="Traceroute (Destination)"
                            name="trace_route_destination_text"
                            isMandatory={true}
                            placeholder="Enter here"
                            isExpandedField={true}
                            showTooltip={true}
                            tooltipText={"Traceroute (Destination)"}
                            textLimit={"1000 characters"}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                        <ImageUpload
                            name="trace_route_destination_file"
                            value={values.trace_route_destination_file}
                            setFieldValue={setFieldValue}
                            isMandatory={true}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                            onFileDownload={onFileDownload}
                            actionType={actionType}
                        />
                         <TextArea
                            label="Ping Results (Source)"
                            name="ping_result_source_text"
                            isMandatory={false}
                            placeholder="Enter here"
                            isExpandedField={true}
                            showTooltip={true}
                            tooltipText={"Ping Results (Source)"}
                            textLimit={"1000 characters"}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                        <ImageUpload
                            name="ping_result_source_file"
                            value={values.ping_result_source_file}
                            setFieldValue={setFieldValue}
                            isMandatory={false}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                            onFileDownload={onFileDownload}
                            actionType={actionType}
                        />
                        <TextArea
                            label="Ping Results (Destination)"
                            name="ping_result_destination_text"
                            isMandatory={false}
                            placeholder="Enter here"
                            isExpandedField={true}
                            showTooltip={true}
                            tooltipText={"Ping Results (Destination)"}
                            textLimit={"1000 characters"}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                        <ImageUpload
                            name="ping_result_destination_file"
                            value={values.ping_result_destination_file}
                            setFieldValue={setFieldValue}
                            isMandatory={false}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                            onFileDownload={onFileDownload}
                            actionType={actionType}
                        />
                         <TextArea
                            label="Additional Information"
                            name="additional_information"
                            isMandatory={false}
                            placeholder="Enter here"
                            isExpandedField={true}
                            showTooltip={true}
                            tooltipText={"Additional Information"}
                            textLimit={"1000 characters"}
                            isPreviewMode={props.isIncidentPreviewModeOn}
                        />
                    </Form>
                    {actionType===ADD_TICKET_ACTION.INCIDENT_DETAILS?
                         <footer className={`${styles.footer} ${actionType===ADD_TICKET_ACTION.INCIDENT_DETAILS ?styles.closedetalpopup:""}`}>
                            <EditButton
                            className={styles.backButton}
                            title={"Close"}
                            style={styles.btn}
                            onClick={()=>{
                                props.setIncidentPreviewMode(false);
                                props.closeForm();
                            }}/>
                         </footer>:
                    <footer className={styles.footer}>
                        {
                           props.isIncidentPreviewModeOn? 
                           <EditButton
                            className={styles.backButton}
                            title={"Edit"}
                            style={styles.btn}
                            onClick={()=>props.setIncidentPreviewMode(false)}
                        />:<EditButton
                            className={styles.backButton}
                            title={"Cancel"}
                            style={styles.btn}
                            onClick={()=>{
                                props.setIncidentPreviewMode(false);
                                props.closeForm();
                            }}
                        />
                        }
                        <Submit
                            id="saveButton"
                            title={props.isIncidentPreviewModeOn?"Submit":"Preview"}
                            form="ip_packet_drop_high_latency_high_utilization"
                            style={styles.btn}
                            isSubmitting={isSubmit}
                            disabled={!isValid || !dirty}
                        />
                    </footer>
}
                </>
            )}

        </Formik>
    )

}

const mapStateToProps = (state)=>{
    return {
        isIncidentPreviewModeOn:state.IncidentManagement.isIncidentPreviewModeOn,
        myRights:state.myRights.myRights,        
    }
  }

const mapDispatchToProps = (dispatch)=>{
    return {
        setIncidentPreviewMode: (payload) => {
            return dispatch(setIncidentPreviewMode(payload))
        },
        addIncident: (payload) => {
            return dispatch(SubmitIncident(payload))
        },
        openAlertPopup: (payload) => {
            return dispatch(openAlertPopup(payload))
          },
          getIncidentList: (next=null)=>{
              return dispatch(getIncidentList(next))
          },
        incidentDownloadFile:(originalFileName,uniqueFileName,messageId,attachmentId,graphInternetMessageId)=>{
            return dispatch(incidentDownloadFiles(originalFileName,uniqueFileName,messageId,attachmentId,graphInternetMessageId))
        },
    }
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(IPPacketDropHighLatencyHighUtilizationDownForm);

