import React,{useEffect} from 'react'
import styles from './myServiceRequest.module.css';
import IMAGES from '../../../../assets/images/images';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../../../../components/loader/Loader";
import moment from 'moment';
import { formatDate, futureDate } from '../../../../utils/arrayUtils';
import { STATUS_ENUM } from '../../../../utils/constants/incidentConstants';
import { USER_TYPE } from '../../../../utils/constants/userConstants';

const ServiceRequestList = ({ data, myRights }) => {
  const navigate = useNavigate();
  const gotosrDetail = (requestId) => {
    navigate(`/ServiceRequestDetail/${requestId}`);
  }
    return (
        <div className={styles.collapsibleContainer}>
        <div className={styles.collapsible}>
        <div className={styles.header}>
          <div> <span  className={styles.SRid} onClick={()=>gotosrDetail(data.id)}>{data.serviceRequestId}</span>
                <span className={`${styles.status} ${styles[data?.status?.value]}`}>
                      {data.status.label}
                </span>
          </div>
          <div className={styles.onhoverdiv}>
            <div className={styles.parentdiv}>
            <div className={styles.submitedOn}>Submitted On:<span className={styles.data}> {formatDate(data.createdAt)}</span></div> 
              {
                <span className={styles.notificationmain}>
                  {data.isServiceDeskCommentReceived && myRights?.userType===USER_TYPE?.CUSTOMER&&(
                  <div className=""><span className={styles.commentnotification}></span> <img className={styles.newcomment}src={IMAGES.newcomment} alt="new comment"/></div>
                  )}
                </span>
              }
            </div>
          </div>
        </div>
         <div className={styles.summary}><span className={styles.issue}></span><span className={styles.summarydata}> {data?.serviceRequestDetails?.title}</span></div>
          <div className={styles.titleContainer}> 
            {myRights?.userType===USER_TYPE?.INTERNAL&&(
              <>
              <div className={styles.DetailsDiv}>Customer Name:<span className={styles.data}> {data?.createdBy?.name}</span></div>
              <div className={styles.border}></div>
              </>
            )}
            {data?.status?.value!==STATUS_ENUM.NEW&&(
              <>
              <div className={styles.DetailsDiv}>OneITSM ID:<span className={styles.data}> {data?.ticketIdServiceDesk}</span></div>
              <div className={styles.border}></div>
              </>
            )}
          <div className={styles.DetailsDiv}>Service Type:<span className={styles.data}> {data.serviceRequestType.label}</span></div>        
          {/* <div className={styles.border}></div> */}
          {/* <div className={styles.DetailsDiv}>Submitted On:<span className={styles.data}> {formatDate(data.createdAt)}</span></div>  */}
          </div>
          {myRights?.userType===USER_TYPE?.CUSTOMER &&(
            <>
              {data.status.value===STATUS_ENUM.CUSTOMER_FEEDBACK_AWAITED&&(
                <div className={`${styles.warning}`}><span className={styles.warningtext}>Please respond by {futureDate(data?.modifiedAt)} to accept or reject the ticket resolution.</span></div>
              )}
              {data.status.value===STATUS_ENUM.NEED_MORE_INFORMATION&&(
                <div className={`${styles.warning}`}><span className={styles.warningtext}>Respond by {futureDate(data?.modifiedAt)}, or the ticket will be auto closed.</span></div>
              )}
            </>
          )}
        </div>
       
      </div>
   );
  };
  

const ServiceRequestViewer = (props) => {
const data = props.serviceList.data;
 useEffect(() => {
  props.getServiceList();
}, []);

const fetchData = () => {
  if (props.serviceList.next) {
    props.getServiceList(props.serviceList.next);
  }
};
    return (
      <div>
      {props.serviceList.isLoading && props.serviceList.data.length == 0 ? (
        <Loader />
      ) : props.serviceList.data.length == 0 ? (
        <div className={styles.Emptydiv}>No Records Found</div>
      ) : (
        <InfiniteScroll
          dataLength={props.serviceList.data?.length}
          next={() => {
            fetchData();
          }}
          hasMore={props.serviceList.next}
          loader={<Loader />}
        >
           {data.map((item) => (
            <ServiceRequestList data={item} myRights={props?.myRights} />
          ))}
        </InfiniteScroll>
      )}
    </div>
    )
}
  
export default ServiceRequestViewer;

