const IMAGES = {
    chevron: require(`./chevron.png`),
    banner: require(`./banner.png`),
    filter:require(`./filter.png`),
    VFloader:require(`./loader.png`),
    close:require(`./close.png`),
    editIcon:require(`./edit.png`),
    arrowup:require(`./arrowup.png`),
    arrowdown:require(`./downarrow.png`),
    vfPops:require(`./vf-pops.png`),
    companyPops:require(`./company-pops.png`),
    vfPopsHover:require(`./vf-pops-hover.png`),
    companyPopsHover:require(`./company-pops-hover.png`),
    arrowright:require(`./arrowright.png`),
    chevronredleft:require(`./chevronredleft.png`),
    chevronredright:require(`./chevronredright.png`),
    success:require(`./success.png`),
    contact:require(`./contact.png`),
    loginBg:require(`./login-bg.png`),
    save:require(`./save.png`),
    eyeIcon:require(`./eye-icon.png`),
    error:require(`./error.png`),
    user:require(`./user.png`),
    logo:require(`./logo.png`),
    notification:require(`./notification.png`),
    ammendService: require(`./ammend.png`),
    downloadExcel: require(`./downloadExcel.png`),
    tier1Icon : require(`./marker1.png`),
    tier2Icon : require(`./marker2.png`),
    otherTierIcon : require(`./marker3.png`),
    availedTierIcon : require(`./marker4.png`),
    chevronOpen: require(`./chevronOpen.png`),
    chevronClose: require(`./chevronClose.png`),
    attach: require(`./attach.png`),
    comment: require(`./comment.png`),
    back: require(`./back.png`),
    infoCircle: require(`./info_circle.png`),
    infoCirclered: require(`./info_circle_red.png`),
    attachment: require(`./attachmentlogo.png`),
    downloadlogo: require(`./downloadlogo.png`),
    attachinfo: require(`./attachinfo.png`),
    attachment_icon:require(`./attachment_icon.png`),
    downloadfile:require(`./downloadfile.png`),
    backarrow:require(`./back_arrow.png`),
    newcomment:require(`./newcomment.png`),
    onboardinfo:require(`./onboardinfo.png`),
    editrole:require(`./editrole.png`),
    attachmenticon:require(`./attachmenticon.png`),
    lastlogin:require(`./lastlogin.png`),
    userinfosearch:require(`./userinfosearch.png`),
    primary:require(`./primary.png`),
    arrows:require(`./arrows.png`),
    searchicon:require(`./searchicon.png`),
}

export default IMAGES