import React, { useState,useEffect } from 'react'
import { Breadcrumbs } from '../../../../../../components/breadcrumbs/Breadcrumbs';
import { Banner } from '../../../../../../components/banner/Banners';
import IMAGES from '../../../../../../assets/images/images';
import { useParams } from 'react-router-dom';
import { ChildContainer, Container } from '../../../../../../components/Container/Containers';
import styles from './onboardingDetail.module.css';
import { useNavigate } from 'react-router-dom';
import  AddComment  from '../addComment/addComment.container';
import SectionGuard from '../../../../../../hoc/guards/SectionGuard';
import { Action, Resource } from '../../../../../../casl/constants';
import CAN from '../../../../../../casl/can';
import { Loader } from '../../../../../../components/loader/Loader';
import { dueDate, formatDate } from '../../../../../../utils/arrayUtils';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FUNCTIONAL_ROLES_CONSTANTS, USER_TYPE } from '../../../../../../utils/constants/userConstants';
import { ON_BOARDING_STATUS } from '../../../../../../utils/constants/userManagementConstant';
import { Incident_Service_Type_Values } from '../../../../../../utils/constants/incidentConstants';

const OnboargingDetailList = ({ data, myRigths,onFileDownload}) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleContent = () => {
      setIsOpen(!isOpen);
    };
    
  const navigate = useNavigate();

  const back = (requestId) => {
    navigate(`/user-management`);
  }

  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const text = data?.additionalInfoText;
  const previewText = text?.slice(0, 100);
  const showViewMore = text?.length>100

  const downloadFile = (file, index,onDownload) => {
    onDownload(file)
  }

    return (
     <div>
         <div className={styles.back}>
         <div>  
          <span><img className={styles.backbutton} src={IMAGES.backarrow} onClick={()=>back()} /></span>
          <span className={styles.id}>{data?.requestId}</span>
          <span className={`${styles.status} ${styles[data?.requestStatus?.value]}`}> {data?.requestStatus?.label} </span>
        </div>
        {myRigths?.functionalRoles[0] === FUNCTIONAL_ROLES_CONSTANTS["commercial_manager_internal"]?.value&& data?.requestStatus?.value==="pending"&&(
          <>
          {!data?.escalationDue?.status&&(
           <div className={styles.tadiglabel}><span className={styles.alert}>Respond by {dueDate(data?.createdAt)}, or the request will be escalated.</span></div>
          )}
           {data?.escalationDue?.status&&(<span className={styles.exclated}><img className={styles.circleinfo} src={IMAGES.infoCircle}/>The request has been escalated.</span>)}
          </>
        )}
      </div>
      <div className={styles.container}>
      <div className={styles.requestDetail}>Request Details</div>
      <div className={styles.listContent}>
             <div className={styles.list}>
                <div className={styles.label}>Requested By</div>
                <div className={styles.value}>{data?.createdBy?.name}</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>Request Assigned To</div>
                <div className={styles.value}>{data?.requestAssignedTo?.label}</div>
             </div>
             <div className={styles.list}>
                <div className={styles.label}>Request Date</div>
                <div className={styles.value}>{formatDate(data?.createdAt)}</div>
             </div>
             {(data?.requestStatus?.value===ON_BOARDING_STATUS.APPROVED ||data?.requestStatus?.value===ON_BOARDING_STATUS.REJECTED)&&(
              <>
                <div className={styles.list}>
                 <div className={styles.label}>{data?.requestStatus?.value===ON_BOARDING_STATUS.APPROVED?"Approved By":"Rejected By"}</div>
                 <div className={styles.value}>{data?.modifiedBy?.name}</div>
                </div>
                <div className={styles.list}>
                 <div className={styles.label}>{data?.requestStatus?.value===ON_BOARDING_STATUS.APPROVED?"Approved On":"Rejected On"}</div>
                 <div className={styles.value}>{formatDate(data?.modifiedAt)}</div>
                </div>
              </>
             )}
             </div>
             <div className={styles.label}>Additional Information</div>
             <div className={styles.value}>
              {data?.additionalInfoText?
              <>
              {isExpanded ? text : `${previewText}${showViewMore?"...":""}`}
              {showViewMore&&(<span className={styles.viewMore} onClick={handleToggle}>{isExpanded ? " View Less" : " View More"}</span>
              )}
              </>:"---"}
             </div>
             {/* {data?.additionalInfoAttachments?.length>0 &&(
             <div className={styles.label}>Attachments</div>
             )} */}
             <div className={styles.parent}>
              {data?.additionalInfoAttachments?.map((item,index)=>
              <div className={styles.attachment} key={index}>
              <img className={styles.attachlogo} src={IMAGES.attachmenticon} />
              <span className={styles.filename}>{item?.originalFileName}</span>
              <img className={styles.download} src={IMAGES.downloadfile}  onClick={() => downloadFile(item, index,onFileDownload)} />
              </div>)}
             </div>
     </div>
     </div>
    )
  };

  const OnboargingDetail = ({ data, myRights }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
     <div>
      
      <div className={styles.container}>
      <div className={styles.requestDetail}>Customer Details</div>
      <div className={styles.list}>
            <div className={styles.label}>Organisation Legal name</div>
            <div className={styles.value}>{data?.displayName}</div>
        </div>
        <div className={styles.requestDetail}>Availed Service</div>
        {data?.requestDetails?.map((detail,index, array)=>
      <>
        <div key={index} className={`${styles.listContent} ${array.length > 1 && index !== array.length - 1 ? styles.borderbottom : ''}`} >
         <div className={styles.list}>
            <div className={styles.label}>Service Type</div>
            <div className={styles.value}>{detail?.serviceType?.label}</div>
         </div>
         {detail?.cpName!==null&&(
           <div className={styles.list}>
           <div className={styles.label}>TADIG Code</div>
           <div className={styles.value}>{detail?.cpName}</div>
        </div>
         )}
        {detail?.serviceType?.value===Incident_Service_Type_Values.IVC_Service && myRights.userType!==USER_TYPE.CUSTOMER&&(
          <>
            <div className={styles.list}>
             <div className={styles.label}>Account Number</div>
             <div className={styles.value}>{detail?.accountNumber}</div>
            </div>
        <div className={styles.list}>
           <div className={styles.label}>Account Abbreviation</div>
           <div className={styles.value}>{detail?.accountAbbr}</div>
        </div>
                </>
              )}
          <div className={styles.list}>
            <div className={styles.label}>Account Manager Contact</div>
            <div className={styles.value}>{detail?.customerAccountManagers?.map((item)=>item.name)}</div>
         </div>
        </div>
      </>
         )}
     </div>
     </div>
    )
  };
  const ApprovalContainer = ({ data, myRights }) => {
   
    return (
      <div className={styles.containerapproval}>
      <div className={styles.actionContent}>
        <div className={styles.feedbackcontent}>
        {`To proceed with onboarding this customer, kindly approve the request. ${myRights?.functionalRoles[0] === FUNCTIONAL_ROLES_CONSTANTS["commercial_manager_internal"]?.value?`If you have any questions, click 'Need More Info.' `:""} If you have any concerns, you also have the option to reject the request`}
        </div>
        <div className={styles.feedbacaction}>
          <div className={styles.feedbackbtn}>
          <SectionGuard canAccess={CAN(Action.CREATE_COMMENT, Resource.CUSTOMER_ONBOARDING_APPROVAL)}>
           <AddComment source={'addComment'} data={data} />
          </SectionGuard>
          <SectionGuard canAccess={CAN(Action.APPROVER, Resource.CUSTOMER_ONBOARDING_REQUEST)}>
           <AddComment source="Reject" data={data}/>
           <AddComment source="Approve" data={data}/>
          </SectionGuard>
          </div>
        </div>
      </div>
     </div>
    )
  };

  const CommentDetail = ({data,myRights}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const handleToggle = () => {
      setIsExpanded(!isExpanded);
    };
    const text = data?.commentMessage
    const previewText = text?.slice(0, 100);
    function getInitials(name) {
      const displayName = name.split(' ').filter(word => isNaN(word) && word).filter((_, i, arr) => i === 0 || i === arr.length - 1)
      .map(word => word[0]).join('').toUpperCase();
      return displayName;
    }
    const showViewMore = text?.length>100
    return (
      <div className={styles.commentContainer}>
      <div className={styles.initalshead}>
        <div className={styles.userp}>
          <span className={`${data?.createdBy?.email === myRights?.email?styles.inital:styles.servicedesk}`}>{getInitials(data?.createdBy?.name)}</span>
          <span className={styles.initatname}>{data?.createdBy?.name}</span>
        </div>
        <div className={styles.attachments}>
          Posted On:
          <span className={styles.date}>
            {formatDate(data?.createdAt)}
          </span>
        </div>
      </div>
      <div className={styles.commenttext}>
        {isExpanded ? text : `${previewText}${showViewMore?"...":""}`}
        {showViewMore&&(
          <span className={styles.viewMore} onClick={handleToggle}>
           {isExpanded ? " View Less" : " View More"}
          </span>
        )}
      </div>
    </div>
    );
  };


const OnboardingDetail = (props)=> {
  let { requestId } = useParams();
  useEffect(() => {
    props.getOnBoardingApprovalDetail(requestId);
    let next=null;
    props.getOnBoardingComments(requestId,next);
    window.scrollTo(0,0);
    return () => {
      props.clearOnboadingComments();
  };
  }, [requestId]);

  const fetchData = () => {
    if (props?.commentsList?.next) {
      props.getOnBoardingComments(requestId,props?.commentsList?.next);
    }
  };

  const onFileDownload=(file)=>{   
    props.downloadFiles(file.originalFileName,file.uniqueFileName)
    .then((result)=>{
        if(result.status){
          const fileData=result.file
          if(fileData?.length>0){
                window.open(fileData?.[0]);
          }
        }else{
          alert("Error while downloding file!");
        }
      }).catch((error)=>{
        alert("Something went wrong!")
      })
    }

  return (
    <Container>
      <Banner image={IMAGES.banner} text={"Onboarding Approvals"}></Banner>
      <ChildContainer>
       {/* {props.requestDetails.map((item) => (
        <CommercialMlist data={item}/>
        ))} */}
         {props?.requestDetails.loading?
           <div className={styles.comentsLoader}>
           <Loader />
         </div>:
         <>
          <OnboargingDetailList data={props?.requestDetails?.onBoardingApprovalDetail} myRigths={props.myRights} onFileDownload={onFileDownload}/>
          <OnboargingDetail data={props?.requestDetails.onBoardingApprovalDetail} myRights={props.myRights}/>
         </>}
         {props?.requestDetails.onBoardingApprovalDetail?.requestStatus?.value===ON_BOARDING_STATUS.PENDING&& !props?.requestDetails.loading &&(
          <ApprovalContainer data={props?.requestDetails.onBoardingApprovalDetail} myRights={props.myRights}/>
          )}
          <div  className={styles.initaltext}>Comment History</div>
          {props?.commentsList?.isLoading && props?.commentsList?.comments.length == 0? (
          <div className={styles.comentsLoader}>
            <Loader />
          </div>
           ) : props?.commentsList?.comments.length  == 0 ? (
            <div className={styles.emptydiv}>No Records Found</div>
           ) : (
           <InfiniteScroll
            dataLength={props?.commentsList?.comments.length} //This is important field to render the next data
            next={() => {
            fetchData();
             }}
            hasMore={props?.commentsList?.next}
            loader={<Loader />}
          >
          {props?.commentsList?.comments?.map((item) => (
            <CommentDetail data={item} myRights={props.myRights}/>
          ))}
        </InfiniteScroll>
      )}
      </ChildContainer>
    </Container>
  )
};

export default OnboardingDetail;