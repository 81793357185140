import { Sidebar } from "../../../../../components/sidebar/Sidebar";
import styles from "./addNewProduct.module.css";
import { useParams } from "react-router-dom";
import React, { useEffect, useId, useState } from "react";
import { Formik, Field, Form, ErrorMessage, useField } from "formik";
import {
  FormFieldLabel as Label,
  FormErrorLabel as ErrorLabel,
} from "../../../../../components/label/Labels";
import {
  FormTextArea,
  TextInput,
} from "../../../../../components/input/inputs";
import * as Yup from "yup";
import { Submit, BackButton } from "../../../../../components/button/buttons";
import FileAttachment from "../../../../../components/fileAttachment/fileAttachment.container";
import { DetailContainer } from "../../../../../components/comment/comment";
import Asterisk from "../../../../../components/asterisk/Asterisk";
import IMAGES from "../../../../../assets/images/images";
import * as DOMPurify from "dompurify";
import Tooltip from "../../../../../components/toolTip/toolTip";
import DropDown from "../../../../../components/dropDown/DropDown";
import { SearchField } from "../../../../../components/search/search";
import CustomerViewer from "../../list/CustomerViewer";
import { useNavigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAccountManagerList, getPrimaryContactList, SubmitRequst } from "../../../../../redux/actions/userManagement/submitOnbardingRequest";
import { openAlertPopup } from "../../../../../redux/actions/Alert/alert.action";
import { Incident_Service_Type_Values } from "../../../../../utils/constants/incidentConstants";
import SectionGuard from "../../../../../hoc/guards/SectionGuard";
import CAN from "../../../../../casl/can";
import { Action, Resource } from "../../../../../casl/constants";
import { getCustomerDetails } from "../../../../../redux/actions/userManagement/customer.action";
const ButtonWrapper = ({ children }) => {
  return <div className={styles.buttonContainer}>{children}</div>;
};
export const Wrapper = ({ children }) => {
  return <div className={styles.wrapperContainer}>{children}</div>;
};

const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldWrapper}>{children}</div>;
};

const Search = ({ label, placeholder, onChange, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? { ...styles.inputError, width: "250px" }
    : { width: "250px" };

  return (
    <FieldWrapper>
      <div>
        <Label text={label} style={styles.labelcompLevel} /> <Asterisk />
      </div>
      <SearchField
        {...field}
        {...props}
        placeholder={placeholder}
        style={styles.additionalStyle}
        onChange={(e) => {
          helpers.setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const CheckBox = ({ name, value, label, onChange, checked, disabled }) => {
  const id = useId();
  return (
    <div className={styles.radioField}>
      <fieldset disabled={checked }>
        <Field
          id={id}
          className={styles.radioInput}
          type="checkbox"
          name={name}
          value={value}
          onChange={onChange}
          checked={true}
          //  disabled={!checked}
        />
      </fieldset>
      <label htmlFor={id} className={styles.radioLabel}>
        {label}
      </label>
    </div>
  );
};

const ImageUpload = ({
  setFieldValue,
  value,
  label = "Supporting Attachments",
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FieldWrapper>
      <div className={styles.labelcomment}>
        <Label text={label} optional={false} style={styles.labelcompLevel} />
      </div>
      <FileAttachment
        name={props.name}
        value={value}
        setFieldValue={setFieldValue}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const TextField = ({ label, placeholder, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError ? styles.inputError : null;
  return (
    <FieldWrapper>
      <div>
        <Label text={label} style={styles.labelcompLevel} />
        <Asterisk />
      </div>
      <TextInput
        {...field}
        {...props}
        placeholder={placeholder}
        style={inputStyle}
        //   onChange={props.onChange}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

  const SelectField = ({ title, options, ...props }) => {
    let _options = options.map((item) => ({
      value: item.value,
      label: item.label,
    }));
    const [field, meta, helpers] = useField(props);
  
    const hasError = meta.touched && meta.error ? true : false;
    return (
      <FieldWrapper>
        <div>
          <Label
            text={title}
            style={`${styles.labelcompLevel} ${props.additionalStyle}`}
          />
          <span className={styles.circlered}>
            <span className={styles.tooltip}>
           <Asterisk /> 
            </span>
          </span>
        </div>
        <DropDown
          options={_options}
          customStyles={props.customStyles}
          onChange={({ label, value }) => {
            props.onChange(value);
            helpers.setValue(value);          
          }}
          value={
            _options && field.value
              ? _options.find((option) => option.value === field.value)
              : ""
          }
          hasError={hasError}
          isDisabled={props.isDisabled}
        />
        {hasError ? <ErrorLabel text={meta.error} /> : null}
      </FieldWrapper>
    );
  };

const TextArea = ({ label, optional = false, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? `${styles.input} ${styles.inputError}`
    : styles.input;
  return (
    <FieldWrapper>
      <div className={styles.labelcomment}>
        <Label text={label} optional={optional} style={styles.labelcompLevel} />
        <span className={styles.circlered}>
          {props.tooltiprquired == "true" ? (
            <span className={styles.tooltip}>
              <Tooltip text={props.tooltiptxt} />
            </span>
          ) : (
            <></>
          )}
        </span>
      </div>
      <FormTextArea {...field} {...props} style={styles.additionalStyle} />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};


const AddNewProduct = (props) => {
  const checkedipx = props?.serviceType?.value==="ipx_service"?true:false;
  const checkedivc = props?.serviceType?.value==="ipx_service"?false:true; 
  const [show, setShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isIPXServicesChecked, setIsIPXServicesChecked] = useState(!checkedipx);
  const [ isInternationalVoiceServicesChecked,setIsInternationalVoiceServicesChecked] = useState(!checkedivc);
  const [optionType, setOptionType] = useState(false);


  const close = () => {
   // props.open();
    setShow(false);

  };

  const handleCheckboxChange = (event, setFieldValue, values) => {
    const { name, value, checked } = event.target;
    let newValues = Array.isArray(values[name]) ? [...values[name]] : [];

    if (checked) {
      newValues.push(value);
    } else {
      newValues = newValues.filter((v) => v !== value);
    }

    setFieldValue(name, newValues);
  

    switch (value) {
      case Incident_Service_Type_Values.IPX_Service:
        setIsIPXServicesChecked(checked);
        break;
      case Incident_Service_Type_Values.IVC_Service:
        setIsInternationalVoiceServicesChecked(checked);
        break;
      default:
        break;
    }
  };

  const primaryContactList = props?.primaryContactList;

  const primaryCustomers = primaryContactList?.map((item) => ({
    value: item?.email,
    label: item?.displayName,
  }));


  const accountManagerList = props.accountManagerList;
  const accountManager = accountManagerList.map((item) => ({
    value: item.email,
    label: item.displayName,
  }));

  useEffect(() => { 
    props.getAccountManagerList();
    props.getPrimaryContactList(props?.customerId);
}, []);


  const validationSchema = Yup.object().shape({
    files: Yup.array().max(3, "You can upload up to 3 files"),
    accountManager: Yup.string().required("Account Manager is required"),
    // accountManagerEmail: Yup.string().required("Account Manager Email is required").email("Invalid email format"),
    primaryContactName: Yup.string().required("Primary Contact is required"),
    // primaryEmailAddress: Yup.string().when('primaryContactName', {
    //   is: (value) =>  value !== "other"  ,
    //   then: () => Yup.string().required("Primary Contact Email is required").email("Invalid email format")  ,
    //   otherwise: () => Yup.string().nullable()
    // }),
    primaryContactNameSecondery: Yup.string().when('primaryContactName', {
      is: (value) =>  value == "other"  ,
      then: () => Yup.string().required("Primary Contact Name is required"),
      otherwise: () => Yup.string().nullable()
    }),
    primaryEmailAddressSecondery: Yup.string().when('primaryContactName', {
      is: (value) =>  value == "other" ,
      then: () => Yup.string().required("Primary Contact Name is required").email("Invalid email format"),
      otherwise: () => Yup.string().nullable()
    }),
    tadigCode: Yup.string().when("ipxServices", {
      is: (val) => val && val.includes(Incident_Service_Type_Values.IPX_Service),
      then: () => Yup.string().required("TADIG Code is required"),
    }),
  });


  const getPrimaryContactName = (email) => {
    let primaryCustomerName = primaryCustomers.filter(
      (item) => item.value == email
    );
    return primaryCustomerName[0].label;
  };
  const getAccountManagerName = (email) => {
    let accountManagerName = accountManager.filter(
      (item) => item.value == email
    );
    return accountManagerName[0].label;
  };

  return (
    <>
    <SectionGuard canAccess={CAN(Action.CREATE, Resource.CUSTOMERS)}>
      <button onClick={() => setShow(true)} className={styles.continue}>
       Add Another Service
      </button>
    </SectionGuard>
      {show && (
        <Sidebar
          isOpen={show}
          headerTxt="Add New Product"
          onClose={close}
          sideNavStyle={styles.sideNavStyle}
        >
          <div>
            <Formik
              validationSchema={validationSchema}
              initialValues={{
                additionalInformation: "",
                files:[],
                tadigCode: "",
                primaryContactName: "",
                primaryEmailAddress: "",
                primaryContactNameSecondery: "",
                primaryEmailAddressSecondery: "",
                accountManager: "", 
                accountManagerEmail: "",
                // accountManager: props.myRights?.displayName, // dont send this email
                // accountManagerEmail: props.myRights.email,
                service:"", 
                ipxServices: isIPXServicesChecked ? [Incident_Service_Type_Values.IPX_Service] : [],
                internationalVoiceServices: isInternationalVoiceServicesChecked? [Incident_Service_Type_Values.IVC_Service] :[],
              }}
              onSubmit={async (values, { resetForm }) => {  
                setIsSubmit(true);
                let obj = {
                 displayName: props.customer,
                 uniqueCustomerRefId: props.customerId,
                 requestType: "extend_existing",
                 requestDetails: [],
                 additionalInfoText: values.additionalInformation,
                 additionalInfoAttachments: values.files,
               };
   
                 if (isIPXServicesChecked) {
                   obj.requestDetails.push({
                     cpName: values.tadigCode,
                     serviceType: values.ipxServices.toString(),
                    
                      customerPrimaryContact: [
                        {
                         // name:  values.primaryContactNameE,
                         name: values.primaryContactName !== "other" ? getPrimaryContactName(values.primaryContactName) : values.primaryContactNameSecondery,              
                      //  email: values.primaryEmailAddress || values.primaryEmailAddressE,
                        email: values.primaryEmailAddress !== "other" ? values.primaryEmailAddress : values.primaryEmailAddressSecondery,              

                        },
                      ],
                      customerAccountManagers: [
                        {
                          name: getAccountManagerName(values.accountManager),
                          email: values.accountManagerEmail,
                        },
                      ],
                   });
                 }
                 if (isInternationalVoiceServicesChecked) {
                    obj.requestDetails.push({
                    cpName:null,
                      serviceType: values.internationalVoiceServices.toString(),
                      customerPrimaryContact: [
                        {
                          // name: values.primaryContactNameE,
                          name: values.primaryContactName !== "other" ? getPrimaryContactName(values.primaryContactName) : values.primaryContactNameSecondery,
                          email: values.primaryEmailAddress !== "other" ? values.primaryEmailAddress : values.primaryEmailAddressSecondery, 
                        },
                      ],
                      customerAccountManagers: [
                        {
                          name: getAccountManagerName(values.accountManager),
                          email: values.accountManagerEmail,
                        },
                      ],
                    });
                  }
                 props.submitRequest(obj).then((result) => {
                     if (result.status) {
                       props.openAlertPopup({
                         message: `Extension request with id: ${result?.res?.data?.requestId} has been sent to the Commercial Manager!`,
                         image: IMAGES.success,
                       });
                       setIsSubmit(false);
                       resetForm();
                       close();
                       // props.getServiceList();
                       props.getDetails(props?.customerId);
                     } else {
                       let error_msg = result?.error?.message ?? "Something went wrong.";
         
                       props.openAlertPopup({
                         message: error_msg,
                         image: IMAGES.error,
                       //  isFormOpen:true
                       });
                      setIsSubmit(false);
                      close();
                     }
                   })
                   .catch((error) => {
                     props.openAlertPopup({
                       message: "Something went wrong.",
                       image: IMAGES.error,
                     //  isFormOpen:true
                     });
                   setIsSubmit(false);
                   close();
                   });
                }}
            >
              {({ isValid, dirty, values, setFieldValue, resetForm, handleChange,setFieldTouched }) => (
                <>
                  <Form className={styles.form} id="Add_Customer">
                    <>
                      <div className={styles.chackboxtxt}>
                        Choose one or more service type
                        <div></div>
                      </div>
                      <div className={styles.chackboxmain}>
                        <div>
                          <CheckBox
                            name="ipxServices"
                            value={Incident_Service_Type_Values.IPX_Service}
                            label="IPX Services"
                            // onChange={(e) =>
                            //   handleCheckboxChange(e, values)
                            // }
                            id="IPX_Services"
                          checked={checkedipx}
                          />
                        </div>
                        <div>
                          <CheckBox
                            id="International_Voice_Services"
                            name="internationalVoiceServices"
                            value={Incident_Service_Type_Values.IVC_Service}
                            label="International Voice Services"
                            // onChange={(e) =>
                            //   handleCheckboxChange(e, values)
                            // }
                          checked={checkedivc}
                          />
                        </div>
                      </div>
                      {(isIPXServicesChecked || isInternationalVoiceServicesChecked) && (
                        <>
                          {isIPXServicesChecked && (
                            <TextField
                              label="TADIG Code"
                              name="tadigCode"
                              type="text"
                              placeholder="Enter ID"
                            />
                          )}
                           <div className={styles.select}>
                            <div
                              className={`${styles.selectwidth} ${styles.dropdownwidths}`}
                            >
                              <SelectField
                                title={"Customer Primary Contact Name"}
                                name="primaryContactName"   
                                options={primaryCustomers}
                                customStyles={styles.obj}
                                placeholder="Enter name"
                               
                               onChange={(value)=>{
                                handleChange(value);
                                setFieldValue("primaryEmailAddress", value)
                               }}
                                
                                   
                                additionalStyle={styles.additionalStyle}
                              />
                            </div>
                             <div className={styles.selectwidth}>
                          
                            {values.primaryEmailAddress !=="other" ? (
                           <TextField
                            label="Customer Primary Email Address"
                             name="primaryEmailAddress"                                     
                             type="text"
                             placeholder=""
                             value={values.primaryEmailAddress}
                             readOnly
                             disabled = {true}
                             />
                         ) : ( null) }
                            </div>
                          </div>

                          {values.primaryEmailAddress=='other' ? (
                          <div className={styles.select}>
                            <div
                              className={`${styles.selectwidth} ${styles.dropdownwidths}`}
                            >
                              <TextField
                                label="Primary Contact Name"
                                name="primaryContactNameSecondery"
                                type="text"
                                placeholder="Enter name"
                              />
                            </div>
                            <div className={styles.selectwidth}>
                              <TextField
                                label="Primary Contact Email Address"
                                name="primaryEmailAddressSecondery"
                                type="text"
                                placeholder="Enter email address"
                              />
                            </div>
                          </div>
                          ) : null}
                           <div className={styles.select}>
                                  <div
                                    className={`${styles.selectwidth} ${styles.dropdownwidths}`}
                                  >
                                    <SelectField
                                      title={"Account Manager"}
                                      name="accountManager"
                                      onChange={(value) => {
                                        handleChange(value);               
                                        setFieldValue(
                                          "accountManagerEmail",
                                          value
                                        );                                       
                                       }}
                                      options={accountManager}
                                      customStyles={styles.obj}
                                      placeholder="Enter name"
                                      additionalStyle={styles.additionalStyle}
                                    />
                                  </div>
                                  <div className={styles.selectwidth}>
                                       <TextField
                                      label="Account Manager Email Address"
                                      name="accountManagerEmail"
                                      type="text"
                                      value={values.accountManagerEmail}
                                      placeholder=""
                                      readOnly
                                      disabled = {true}
                                    />
                                  </div>
                                </div>
                          <TextArea
                            label={"Additional Information"}
                            id="additionalInformation"
                            name="additionalInformation"
                            placeholder="Enter the information"
                          />
                          <ImageUpload
                            name="files"
                            value={values.files}
                            setFieldValue={setFieldValue}
                            optional={false}
                          />
                        </>
                      )}
                    </>
                    <div className={styles.footer}>
                      <div>
                        <BackButton
                          className={styles.backButton}
                          style={styles.back}
                          title={"Back"}
                          closePopup={close}
                        />
                      </div>

                      <div>
                        <>
                          <Submit
                            id="saveButton"
                            className={styles.saveButton}
                            style={styles.submit}
                            title={"Submit"}
                            form="Add_Customer"
                            disabled={!dirty || !isValid}
                            isSubmitting={isSubmit}
                          />
                        </>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </Sidebar>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    submitting: state.submitCustomerDetails.loading,
    myRights :state.myRights.myRights,
    customer:state.CustomerDetail.customer?.displayName,
    primaryContactList: state.onboardingRequest.primaryContactList.data,
    accountManagerList: state.onboardingRequest.accountMManagerList.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitRequest: (payload) => {
      return dispatch(SubmitRequst(payload));
    },
    openAlertPopup: (payload) => {
      return dispatch(openAlertPopup(payload));
    },
    getPrimaryContactList: (requestId) => {
        return dispatch(getPrimaryContactList(requestId));
        },
    getAccountManagerList: (payload)=>{
            return dispatch(getAccountManagerList(payload))
        },
      getDetails: (customerID) => {
          return dispatch(getCustomerDetails(customerID))
      },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewProduct);
