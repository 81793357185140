import React, { useEffect, useState } from "react";
import { ChildContainer, Container } from "../../components/Container/Containers";
import IMAGES from "../../assets/images/images";
import { Tabs } from "../../components/tabs/Tabs";
import MyIncidentViewer from "./myIncidents/list/myIncidentViewer.container";
import CloseTicketViewer from "./myServiceRequest/list/myserviceRequestViewer.container";
import styles from "./incidentManagement.module.css";
import AddTicket from "./myIncidents/add/addTicket.container";
import AddServiceRequest from "./myServiceRequest/add/addServiceRequest.container";
import {connect} from "react-redux";
import { setIncidentActiveTab} from "../../redux/actions/incidentManagement/incidentTab.action";
import { ADD_TICKET_ACTION, MY_INCIDENT_TABS } from "../../utils/constants/incidentConstants";
import SectionGuard from "../../hoc/guards/SectionGuard";
import CAN from "../../casl/can";
import { Action, Resource } from "../../casl/constants";
import { USER_TYPE } from "../../utils/constants/userConstants";
import SecondaryTooltip from "../../components/secondaryToolTip/secondaryToolTip";

const Banner=({myRights})=>{
  return(
    <div className={styles.bannerContainer}>
            <img src={IMAGES.banner} className={`${myRights?.userType===USER_TYPE.CUSTOMER?styles.image:styles.bannerimage}`} />
            <div className={styles.textbanner}>
              <div className={styles.text}> Incident Management</div>
                <SectionGuard canAccess={CAN(Action.CREATE,Resource.INCIDENTS) || CAN(Action.CREATE,Resource.SERVICE_REQUESTS)}>
                  <div className={styles.description}>Raise an incident for issues needing resolution, or a service request for more service information.</div>
                  <div className={styles.buttonContainer}>
                    <div className={styles.buttondiv}>
                     <SectionGuard canAccess={CAN(Action.CREATE,Resource.INCIDENTS)}>
                     <SecondaryTooltip text={"Raise New Incident"}>
                      <AddTicket actionType={ADD_TICKET_ACTION.NEW_INCIDENT}/>
                     </SecondaryTooltip>
                     </SectionGuard>
                    </div>
                    <div className={styles.buttondiv}>
                     <SectionGuard canAccess={CAN(Action.CREATE,Resource.SERVICE_REQUESTS)}>
                     <SecondaryTooltip text={"Raise Service Request"}>
                      <AddServiceRequest/>
                     </SecondaryTooltip>
                     </SectionGuard>
                    </div>
                  </div>
                </SectionGuard>
            </div>
        </div>
  )
}
const IncidentManagement = (props) => {
  const [activeTab, setActiveTab] = useState("");
  // const [tabOptions, setTabOptions] = useState([]);
  
   
   // if(CAN(Action.LIST, Resource.DEMANDS) && CAN(Action.LIST, Resource.LIVE_SERVICES)){
   //   setTabOptions([MY_INCIDENT_TABS.MY_INCIDENTS, MY_INCIDENT_TABS.MY_SERVICE_REQUEST]);
   // }else if(CAN(Action.LIST, Resource.LIVE_SERVICES)){
   //props.setIncidentActiveTab(MY_INCIDENT_TABS.MY_INCIDENTS);
   // }else if(CAN(Action.LIST, Resource.DEMANDS)){
    //props.setIncidentActiveTab(MY_INCIDENT_TABS.MY_SERVICE_REQUEST);
   // }
   
   const tabOptions = [
       {
         key: MY_INCIDENT_TABS.MY_INCIDENTS,
         label: "Incidents",
         canAccess: CAN(Action.LIST,Resource.INCIDENTS),
       },
       {
         key: MY_INCIDENT_TABS.MY_SERVICE_REQUEST,
         label:"Service Requests",
         canAccess: CAN(Action.LIST,Resource.SERVICE_REQUESTS),
       }
     ];
     
  const getActiveTab = (tab) => {
    props.setIncidentActiveTab(tab);
  }

  return (
    <Container>
      <Banner myRights={props.myRights}/>
      <Tabs option={tabOptions} defaultActiveTab={props.incidentActiveTab} getActiveTab={getActiveTab} />
      <ChildContainer>
        {
          props.incidentActiveTab === MY_INCIDENT_TABS.MY_INCIDENTS?
           <MyIncidentViewer/>:
          props.incidentActiveTab === MY_INCIDENT_TABS.MY_SERVICE_REQUEST?
         <CloseTicketViewer/>:<></>
        }
      </ChildContainer>
    </Container>
  )
};

// export default IncidentManagement;

const mapStateToProps = (state) => { 
  return {
    incidentActiveTab:state.incidentTab.incidentActiveTab,
    myRights : state.myRights.myRights
  }
}

const mapDispatchToProps = (dispatch)=>{
  return {
    setIncidentActiveTab: (tab) => {
        return dispatch(setIncidentActiveTab(tab))
      },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IncidentManagement);