import React, { useEffect, useState } from "react";
import { Formik, Field, Form, useField } from 'formik';
import styles from "../comment/comment.module.css";
import {
  FormFieldLabel as Label,
  FormErrorLabel as ErrorLabel,
} from "../../../components/label/Labels";
import * as Yup from "yup";
import { Submit, BackButton } from "../../../components/button/buttons";
import { DetailContainer } from "../../../components/comment/comment";
import { SERVICE_STATUS_OPTIONS } from "../../../utils/constants/serviceConstants";
import DropDown from "../../../components/dropDown/DropDown";
import FileAttachment from "../../../components/fileAttachment/fileAttachment.container";
import { FormTextArea } from "../../../components/input/inputs";
import { Alert } from "../../../components/alert/alerts";
import Asterisk from "../../../components/asterisk/Asterisk";
import IMAGES from "../../../assets/images/images";
import { Sidebar } from "../../../components/sidebar/Sidebar";
import * as DOMPurify from 'dompurify';

let obj = {
  width: "auto"
}

const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldWrapper}>{children}</div>;
};
const ButtonWrapper = ({ children }) => {
  return <div className={styles.buttonContainer}>{children}</div>;
};
const SelectField = ({ title, options, defaultValue, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  let _options = options.map((item) => ({ value: item.value, label: item.label }));
  return (
    <FieldWrapper>
      <div>
        <Label text={title} /><Asterisk />
      </div>
      <DropDown
        options={_options}
        customStyles={obj}
        onChange={({ label, value }) => {
          helpers.setValue(value);
        }}
        value={
          _options.filter(option =>
            option.value === field.value)
        }
        hasError={hasError}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const TextArea = ({ label, optional = false, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? `${styles.input} ${styles.inputError}`
    : styles.input;
  return (
    <FieldWrapper>
      <div>
      <Label text={label} optional={optional} /><Asterisk />
      </div>
      <FormTextArea {...field} {...props} style={inputStyle} />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const ImageUpload = ({
  setFieldValue,
  value,
  label = "Supporting Attachments",
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FieldWrapper >
      <Label text={label} optional={false} />
      <FileAttachment setFieldValue={setFieldValue} name={props.name} value={value} />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};


const ServiceStatus = (props) => {
  let [isSubmit, setIsSubmit] = useState(false);
  let [isAlert, setIsAlert] = useState(false);
  const serviceDetail = props.serviceData.data;
  let _initialValues = {
    status: props.status,
    comment: '',
    serviceId: typeof serviceDetail !== "undefined" && serviceDetail != null ? serviceDetail.id : '',
    files:[]
  };

  const validationSchema = Yup.object({
    status: Yup.string().required("Required"),
    comment: Yup.string().max(1000,"Comment  must be at most 1000 characters").required("Required"),
    files:Yup.array().max(3)
  });

  const closePopup = () => {
    _initialValues = {      
      status: "",
      comment: "",
      serviceId: typeof serviceDetail !== "undefined" && serviceDetail != null ? serviceDetail.id : '',
      files:[]
    }
    props.closeServiceStatusForm();
  }
  const [message, setMessage] = useState({
    message: "",
    image: IMAGES.success
  });

  const closeServiceStatusSidebar = () => {
    props.closeServiceStatusForm();
  }

  return (
    <>
    <Sidebar
        isOpen={props.isServiceStatusFormOpen}
        onClose={closeServiceStatusSidebar}
        headerTxt={"Change Status"}
      >
    <Formik
      initialValues={_initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange
      onSubmit={async (values, { resetForm }) => {
        setIsSubmit(true);
        let requestId = typeof serviceDetail !== "undefined" && serviceDetail != null ? serviceDetail.id : '';
        let obj = {
          status: values.status,
          uniqueCustomerRefId:props?.serviceData?.data?.uniqueCustomerRefId
        }
        props.updateServiceStatus(obj, requestId).then((response) => {
          if (response) {
          }
        }).catch((error) => {
          alert("Something went wrong while updating service status")
        })

        let commentObj = { "files": [] };
        commentObj.comment = DOMPurify.sanitize(values?.comment);
        commentObj.files = values?.files;
        commentObj.serviceId = typeof serviceDetail !== "undefined" && serviceDetail != null ? serviceDetail.id : '';
        props.addComment(commentObj).then((result) => {
          if (result?.status) {
            // setMessage({
            //   message: "Service request updated successfully",
            //   image: IMAGES.success
            // });
            setIsSubmit(false);
            resetForm();
           // setIsAlert(true);
            props.openAlertPopup({
              message: "Demand updated successfully!",
              image: IMAGES.success
            });
            if(props.openFrom=="NewServiceViewer"){
              props.getServicesList();
            }else if(props.openFrom=="ServiceDetails"){
              props.getServiceDetail(props.serviceData?.data?.id);
              props.getComments(props.serviceData?.data?.id);
            }
          
            props.closeServiceStatusForm();
          }
          else{
            setIsSubmit(false);
            let error_msg = result?.error?.message ?? "Something went wrong.";
            props.closeServiceStatusForm();
            // setMessage({
            //   message: error_msg,
            //   image: IMAGES.error
            // });
            // setIsAlert(true);
            props.openAlertPopup({
              message: error_msg,
              image: IMAGES.error
            });
          }
        })
          .catch((error) => {
            setIsSubmit(false);
            
                  // setMessage({
                  //   message: "Something went wrong.",
                  //   image: IMAGES.error
                  // });
                  // setIsAlert(true);
                  props.openAlertPopup({
                    message: "Something went wrong.",
                    image: IMAGES.error
                  });
                  props.closeServiceStatusForm();
          });

      }}
    >
      {({ isValid, dirty, values, setFieldValue, resetForm }) => (

        <Form>
          {typeof serviceDetail !== "undefined" && serviceDetail != null ?
            <DetailContainer data={serviceDetail} /> : <></>}
          <br />
          <SelectField
            title={"Change Status"}
            name="status"
            options={SERVICE_STATUS_OPTIONS}
            defaultValue={props.status}
          />
          <br />
          <TextArea
            label={"Comment"}
            id="description"
            name="comment"
          />
          <div className={styles.commentValidation}>1000 characters</div>
          <br />
          <ImageUpload
            name="files"
            value={values.files}
            setFieldValue={setFieldValue}
            optional={false}
          />
          <hr className={styles.separator} />
          <ButtonWrapper>
            <BackButton id="backButton"
              style={styles.submit}
              title={"Back"} closePopup={closePopup}
            />
            <Submit
              id="saveButton"
              style={styles.submit}
              title={"Save"}
              isSubmitting={isSubmit}
              //disabled={!isValid || !dirty}
            />
          </ButtonWrapper>

        </Form>
      )}
    </Formik>
    </Sidebar>
{/* 
{isAlert && (
  <div
    style={{
      zIndex: 20,
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
    }}
  >
    <Alert
      image={message.image}
      message={message.message}
      onClose={setIsAlert}
    />
  </div>
)} */}
</>
  )
};


export default ServiceStatus;

